.react-select2__control {
    min-height: 28px !important;
    background-color: #F2EDF0 !important;
    cursor: pointer !important;
    border-color: #80506F !important;
}
.react-select2__control--is-focused {
    box-shadow: 0 0 0 1px #AA8A9F !important;
}
.react-select2__indicator-separator {
    display: none;
}


.react-select2__menu-list {
    padding: 0 !important;
}
.react-select2__menu-list::-webkit-scrollbar {
    width: 8px;
}
.react-select2__menu-list::-webkit-scrollbar-thumb {
    background: rgba(170, 138, 159, 0.53);
    border-radius: 8px;
}
.react-select2__menu-list::-webkit-scrollbar-thumb:hover {
    background: rgba(170, 138, 159, 1);
}

.react-select2__option--is-focused {
    background-color: rgba(246, 246, 246, 0.7) !important;
}
.react-select2__option--is-selected {
    background-color: #C6C6C6 !important;
}

.react-select2__indicator {
    padding: 0 !important;
}
