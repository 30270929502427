.chat-button {
    z-index: 10;
    width: 60px;
    height: 60px;
    position: fixed;
    right: 64px;
    bottom: 75px;
    border-radius: 30px;
    background: #90B9D7;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}
.chat-container {
    z-index: 10000;
    display: flex;
    flex-direction: column;
    position: fixed;
    width: 410px;
    height: 570px;
    max-height: 80vh;
    right: 64px;
    bottom: 75px;
    background: #FFFFFF;
    box-shadow: -1px 3px 20px rgba(0, 0, 0, 0.07);
    border-radius: 8px;
}
.chat-body {
    overflow: hidden;
    display: flex;
    flex: 1;
    flex-direction: column;
}
.chat-message-container {
    display: flex;
    width: 100%;
    min-height: 50px;

    border-top-width: 1px;
    border-color: #EBE8E8;
}
