.modal {
    position:fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background-color: #E1E4E5CC;
    display: flex;
    align-items: center;
    justify-content: center;
    max-height: 100%;
    overflow-y: auto;
}

.modal > div > svg {
    position: absolute;
    top: 16px;
    right: 15px;
}

.modal > div {
    position: relative;
    padding: 32px 50px 41px;
    background: #FFFFFF;
    box-shadow: 0 7px 20px rgba(0, 0, 0, 0.07);
    border-radius: 8px;
}
