.login-tabs {
    display: flex;
    flex-direction: row;
    align-self: center;
    justify-content: space-between;
    width: 248px;
    margin-bottom: 40px;
}

.login-tab {
    user-select: none;
    padding-top: 26px;
    width: 104px;
    display: flex;
    justify-content: center;
    border-top-width: 2px;
    border-color: white;
    transition: all .2s ease;
    font-size: 18px;
    cursor: pointer;
}
.login-tab-inactive {
    color: #979797;
}
.login-tab-inactive:hover {
    border-color: #979797;
}
.login-tab-active {
    border-color: #80506F;
    color: #80506F;
}
