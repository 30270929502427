.checkbox-input input {
    display: none;
}
.checkbox-input label {
    user-select: none;
    cursor: pointer;
    display: flex;
    align-items: center;
}
.checkbox-input label span {
    font-weight: 400;
    /*font-size: 14px;*/
}
