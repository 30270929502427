.profile-my-team {
    display: flex;
    align-items: flex-start;
    color: #90B9D7;
}
.profile-my-team > span {
    font-size: 18px;
    padding-left: 12px;
    line-height: 35px;
    color: #90B9D7;
}
