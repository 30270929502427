.profile-password-modal {
    width: 485px;
}
.profile-password-modal > p {
    font-size: 25px;
    color: #4A2E4D;
    margin-bottom: 28px;
}
.profile-password-modal > div {
    padding-bottom: 15px;
    display: flex;
}
.profile-password-modal > div > div {
    width: 100%;
}
.profile-password-modal > div > div > input {
    width: 100%;
}
.profile-password-modal > div > label > p {
    color: #90B9D7;
    font-size: 14px;
    width: 170px;
    line-height: 35px;
}
.profile-password-modal > div > label > p > span {
    color: #80506F;
}
.profile-password-modal > button {
    margin-top: 19px;
}
