.exhibition-container {
    padding: 10px 0;
    display: flex;
}
.exhibition-container > svg {
    margin-left: 14px;
    cursor: pointer;
}
.exhibition-container > span {
    display: flex;
    flex: 1;
    margin-left: 12px;
    flex-direction: column;
    font-weight: 500;
    font-size: 14px;
    color: #80506F;
}

.exhibition-container > span > span {
    color: #AFBEC4;
}
