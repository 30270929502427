.chat-header {
    display: flex;
    width: 100%;
    padding: 11px 19px 7px;
    justify-content: space-between;
    border-bottom-width: 1px;
    border-color: #c4c4c4;
}
.chat-header > div {
    display: flex;
    flex: 1;
}
.chat-header > div > span {
    font-size: 18px;
    color: #7F7F7F;
}
.chat-header > div > span > span {
    color: #90B9D7;
}
.chat-header > div > div {
    height: 36px;
    width: 36px;
    border-radius: 18px;
    background: black;
    margin-right: 9px;
}
