.login-container {
    margin-top: 48px;
    box-shadow: 0 7px 20px rgba(0, 0, 0, 0.07);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    width: 420px;
    padding: 0 53px 31px;
    overflow: hidden;
}
.login-title {
    text-align: center;
    font-size: 16px;
    color: #AFBEC4;
    padding-bottom: 40px;
}
.input-form {
    padding: 0 18px;
    line-height: 33px;
    color: #000000;
    font-size: 16px;
    font-weight: 300;
    background: rgba(175, 190, 196, 0.1);
    border: 1px solid rgba(229, 229, 229, 0.9);
    border-radius: 3px;
}
.input-form:focus {
    border-color: #A4C8E2;
    outline: none;
}
.login-password-input {
    margin-top: 20px;
    height: 35px;
    transition: .3s ease-in-out;
}
.login-has-password-input {
    padding-top: 20px;
}
.login-button {
    margin-top: 40px;
}
.login-agreement {
    white-space: pre;
    margin-top: 18px;
    font-size: 12px;
    color: #AFBEC4;
}
.login-agreement a {
    font-size: 12px;
    color: #A4C8E2;
}
.transition-hidden {
    margin-top: 0;
    height: 0;
    opacity: 0;
    border-width: 0;
}
