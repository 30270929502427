.password-input {
    position: relative;
}
.password-input > span {
    padding-top: 2px;
    padding-left: 18px;
    padding-bottom: 12px;
    font-size: 14px;
    line-height: 24px;
    color: #AFBEC4;
}
.password-input > svg {
    top: 10px;
    right: 10px;
    cursor: pointer;
    position: absolute;
}
