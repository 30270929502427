.header-container {
    background: white;
    border-color: #AA8A9F;
    border-bottom-width: 2px;
    display: flex;
    justify-content: center;
    padding-top: 38px;
    padding-bottom: 21px;
}
.header-inner-container {
    padding: 0 59px;
    flex: 1;
    display: flex;
    max-width: 1240px;
}

.header-logo-container {
    /*flex: 1;*/
}

.header-content-container {
    flex: 3;
    display: flex;
    align-items: center;
    padding-bottom: 33px;
}


.header-tabs-container {
    flex: 2;
    display: flex;
    justify-content: space-around;
}

.header-right-container {
    justify-content: space-between;
    align-items: center;
    display: flex;
    flex: 1;
}

.header-tab {
    color: inherit;
    line-height: 28px;
    padding: 0 8px;
    font-size: 16px;
    text-transform: uppercase;
}
.header-tab-active {
    color: white;
    background: #A4C8E2;
    padding-top: 4px;
    padding-bottom: 4px;
}

.sign-in-button {
    font-weight: 700;
    font-size: 12px;
    padding: 0 10px;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: row;
    height: 40px;
    min-width: 125px;
    background: #AA8A9F;
    color: white;
}

.header-menu-modal {
    position: absolute;
    top: 30px;
    right: 4px;
    padding: 6px 20px;
    background: white;
    box-shadow: 0 7px 20px rgba(0, 0, 0, 0.07);
    border-radius: 8px;
}

.header-menu-modal-option {
    cursor: pointer;
    padding: 10px 0;
    align-items: center;
    display: flex;
    flex-direction: row;
}

.icon-inactive {
    color: #AFBEC4;
    stroke: #AFBEC4;
    fill: #AFBEC4;
}
.icon-active {
    color: #90B9D7;
    stroke: #90B9D7;
    fill: #90B9D7;
}

.header-menu-modal-option-text {
    padding-left: 10px;
    white-space: nowrap;

    font-size: 13px;
}
