.login-code-input-row {
    display: flex;
    flex: 1;
    justify-content: space-between;
}

.login-code-input-row-tab {
    font-weight: 600;
    font-size: 22px;
    text-align: center;
    width: 60px;
    height: 50px;
}
