.catalog-scroll::-webkit-scrollbar {
    width: 8px;
}

.catalog-scroll::-webkit-scrollbar-thumb {
    background: rgba(170, 138, 159, 0.53);
    border-radius: 8px;
}

.catalog-scroll::-webkit-scrollbar-thumb:hover {
    background: rgba(170, 138, 159, 1);
}

.catalog-header {
    margin-right: 8px;
}
