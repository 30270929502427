.footer-container {
    background: #F2EEF1;
    display: flex;
    padding: 30px 58px 87px;
    justify-content: center;
}
.footer-inner-container {
    flex: 1;
    display: flex;
    max-width: 1240px;
    justify-content: space-between;
}

.footer-block {
    display: flex;
    flex-direction: column;
}

.footer-field {
    font-size: 14px;
    padding-bottom: 5px;
    color: #7F7F7F;
}

.footer-field-value {
    color:  rgba(128, 80, 111);
    font-size: inherit;
}

.footer-trademark {
    font-size: 12px;
}

.footer-networks {
    display: flex;
    flex-direction: row;
    padding-top: 8px;
    padding-bottom: 16px;
    justify-content: space-between;
}

.footer-stores {
    display: flex;
    flex-direction: row;
    padding-top: 8px;
    justify-content: space-between;
}

.footer-title {
    font-weight: 500;
    font-size: 18px;
    color: #4A2E4D;
    line-height: 22px;
    padding-bottom: 15px;
}

.footer-title-big {
    font-size: 21px;
    padding-bottom: 0;
}
