.profile-my-team-modal {
    width: 1060px;
    min-height: 244px;
}
@screen mb {
    .profile-my-team-modal {
        @apply w-auto;
    }
}
.profile-my-team-modal > table {
    margin-top: 25px;
    margin-bottom: 15px;
}

.profile-my-team-modal .profile-button {
    height: 45px;
}

.profile-my-team-modal input {
    font-size: 14px;
}

.profile-my-team-modal select {
    width: 110px;
    font-size: 14px;
}

.text-button {
    font-weight: 500;
    font-size: 18px;
    color: #90B9D7;
    padding: 0 32px;
    line-height: 45px;
}
.profile-my-team-modal-table-big {
    color: #2E476E !important;
    font-weight: 500 !important;
    font-size: 12px !important;
}

.profile-my-team-modal-table-big-grey {
    font-size: 12px !important;
}
