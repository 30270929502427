.profile-info-field {
    display: flex;
    align-items: center;
    color: #2E476E;
    font-weight: 500;
    padding: 3px 0;
}
.profile-info-field span {
    color: #AFBEC4;

    margin-left: 12px;
    margin-right: 8px;
    font-weight: 600;
}
