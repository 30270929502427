.rc-slider-track {
    background-color: #80506F;
}
.rc-slider-rail {
    background-color: #AFBEC4;
    border-radius: 8px;
}

.rc-slider-dot {
    transform: scale(0.25, 1);
    border: none;
    border-radius: 0;
}

.rc-slider-mark {
    top: -18px;
}


.rc-slider-mark > .rc-slider-mark-text-active {
    color: #999;
}
.rc-slider-mark > .rc-slider-mark-text-active ~ .rc-slider-mark-text-active {
    color: #666;
}

.rc-slider-mark-text {
    font-size: .625rem;
}

.rc-slider {
    margin: 23px 0 3px;
}

.rc-slider-handle:empty, .rc-slider-handle-inner {
    transition-duration: .2s;
    transition-property: transform;
    width: 20px;
    height: 20px;
    border-width: 4px;
    border-color: #A4C8E2 !important;
}
.rc-slider-handle:empty {
    margin-top: -8px;
}
.rc-slider-handle-inner {
    border-radius: 10px;
    background-color: white;
    position: absolute;
    left: -5px;
    top: -5px
}
.rc-slider-handle {
    box-shadow: none !important;
}
.rc-slider-handle:empty:hover, .rc-slider-handle:empty:active {
    transform: translateX(-50%) scale(1.1, 1.1) !important;
}
.rc-slider-handle-inner:hover, .rc-slider-handle-inner:active {
    transform: scale(1.1, 1.1) !important;
}
