@tailwind base;
@tailwind components;

[x-cloak] { display: none; }

html {
  display: flex;
  min-height: 100%;
}

div, input {
  border: 0 solid;
  box-sizing: border-box;
}

::placeholder {
  color: #AFBEC4;
}

body {
  display: flex;
  flex: 1;
  margin: 0;
}

#root {
  display: flex;
  flex-direction: column;
  flex: 1;
}

a {
  text-decoration: none;
  font-size: 16px;
}

.body-container {
  display: flex;
  flex: 1;
  justify-content: center;
  padding: 41px 60px 175px;
  min-height: 766px;
}
.body-inner-container {
  position: relative;
  /*display: flex;
  flex: 1;
  justify-content: center;*/
  max-width: 1240px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance:textfield;
}


.default-button {
  min-width: 100px;
  padding: 14px 30px;
  background: #80506F;
  height: 45px;
  font-weight: 500;
  font-size: 16px;
  display: flex;
  color: white;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  transition: background-color .3s ease-in-out, color .3s ease-in-out;
  outline: none !important;
  box-sizing: border-box !important;
  border: 1px solid;
}
.default-button:disabled {
  cursor: default;
  background: #c6c6c6;
}
.default-button:enabled:hover {
  background: #AA8A9F;
}
.default-button:enabled:focus {
  color: #80506F;
  background: rgba(170, 138, 159, 0.2);
  border-color: #80506F;
}

.default-button-second {
  background: #90B9D7;
}

.default-button-second:hover {
  background: #A4C8E2;
}

.default-button-second:focus {
  color: #2E476E;
  background: rgba(164, 200, 226, 0.4);
  border-color: #2E476E;
}
.button-space-right {
  margin-right: 15px;
}

@tailwind utilities;
