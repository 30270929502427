.profile-container {
    /*display: flex;*/
    /*flex: 1;*/
}
.profile-inner-container {
    display: flex;
    flex-direction: row;
    flex: 1;
    margin: 0 -50px;
}
.profile-inner-container > div {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin: 0 50px;
}

.profile-header {
    display: flex;
    font-size: 28px;
    padding-bottom: 33px;
    color: #4A2E4D;
}
.profile-header > span {
    font-size: 18px;
    padding-left: 12px;
    color: #90B9D7;
}
.profile-pre-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 67px;
}
.profile-avatar {
    width: 300px;
    height: 70px;
    background: #FFFFFF;
    box-shadow: 0 7px 64px rgba(0, 0, 0, 0.07);
    border-radius: 6px;
}

.profile-avatar > img {
    display: block;
    width: auto;
    height: 100%;
    margin: auto;
}

.profile-link {
    text-decoration: underline;
    color: #80506F;
    font-weight: 500;
    font-size: 14px;
}
.profile-rating-title {
    padding-bottom: 4px;
    font-size: 14px;
    font-weight: 500;
    color: #80506F;
    display: flex;
    justify-content: center;
}
.profile-info {
    padding-bottom: 18px;
}
.profile-description {
    font-size: 14px;
    font-weight: 400;
    color: #7F7F7F;
}
.profile-exhibitions {
    padding-bottom: 3px;
    padding-top: 40px;
    color: #2E476E;
    font-weight: 600;
    font-size: 14px;
}
.profile-add-exhibition {
    padding-top: 10px;
    display: flex;
    flex: 1;
}
.profile-add-exhibition > svg {
    cursor: pointer;
}
.profile-buttons, .profile-buttons div {
    display: flex;
    flex: 1;
    margin: 102px -50px 0;
}
.profile-buttons div {
    margin: 0 40px;
}


.profile-buttons button {
    margin: 0 10px;
}

.profile-button {
    min-width: 100px;
    padding: 14px 30px;
    height: 45px;
    font-weight: 500;
    font-size: 16px;
    white-space: nowrap;
}
