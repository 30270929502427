.profile-invite-modal {
    width: 892px;
    display: flex;
}
.profile-invite-modal-divider {
    width: 1px;
    background: #AFBEC4;
    margin: 0 40px 51px;
}

.profile-invite-modal select {
    color: #80506F;
}

.profile-invite-modal input {
    background: #FFFFFF;
    border: 1px solid #E9EFF4;
    height: 25px;
    padding: 0 7px;
    box-shadow: inset 0 4px 8px rgba(151, 151, 151, 0.11);
}
