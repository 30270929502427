.login-resend-text {
    white-space: pre;
    padding: 43px 0 32px;
    font-size: 16px;
    color: #AFBEC4;
    text-align: center;
}
.login-resend-highlighted {
    font-weight: 600;
    font-size: 16px;
    color: #90B9D7;
}
