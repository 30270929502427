.login-country-flag {
    width: 26px;
    height: 26px;
    min-width: 26px;
}
.login-country-flag-row {
    display: flex;
    align-items: center;
}
.login-country-flag-row span {
    font-weight: inherit;
    padding-left: 12px;
    font-size: 12px;
    line-height: 16px;
}

.login-phone-container {
    display: flex;
    justify-content: space-between;
    position: relative;
}
.login-country-input {
    width: 60px;
    display: flex;
    padding: 0;
}
.login-code-input {
    padding: 0 6px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    width: 65px;
}
.login-phone-input {
    width: 160px;
}

.react-select__control {
    cursor: pointer !important;
    min-height: unset !important;
    flex: 1;
    padding-left: 6px;
    padding-right: 13px;
    border: none !important;
    background: none !important;
}
.react-select__menu {
    width: 138px !important;
    overflow: hidden;
}
.react-select__menu-list {
    padding: 0 !important;
    max-height: 200px !important;
}
.react-select__menu-list::-webkit-scrollbar {
    width: 8px;
}
.react-select__menu-list::-webkit-scrollbar-thumb {
    background: rgba(170, 138, 159, 0.53);
    border-radius: 8px;
}
.react-select__menu-list::-webkit-scrollbar-thumb:hover {
    background: rgba(170, 138, 159, 1);
}

.react-select__control--is-focused {
    box-shadow: none !important;
}
.react-select__value-container {
    padding: 0 !important;
    overflow: unset !important;
}
.react-select__single-value {
    display: flex;
    margin: 0 !important;
    overflow: unset !important;
}
.react-select__input {
    top: -14px;
    left: -6px;
    padding: 0 13px;
    z-index: 10;
    opacity: 0;
    pointer-events: none;
    background-color: hsl(0,0%,100%);
    border-radius: 4px;
    box-shadow: 0 0 0 1px hsla(0,0%,0%,0.05), 0 4px 11px hsla(0,0%,0%,0.05);
    position: absolute;
    width: 138px;
}

.react-select__control--menu-is-open .react-select__input {
    opacity: 1;
    pointer-events: inherit;
    border-color: #A4C8E2;
    border-width: 1px;
}

.react-select__option--is-focused {
    background-color: rgba(246, 246, 246, 0.7) !important;
}
.react-select__option--is-selected {
    background-color: #C6C6C6 !important;
}
